.contactSection {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Light background */
}

.overlay {
  background-color: rgba(255, 255, 255, 0.9); /* Light semi-transparent overlay */
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
  max-width: 600px;
  width: 90%;
  color: #333; /* Darker text */
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333; /* Darker heading */
}

.subtext {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555; /* Subtle text color */
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contactForm input,
.contactForm textarea {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ddd; /* Light border */
  font-size: 1rem;
  color: #333;
  background-color: #fff;
  transition: all 0.3s ease;
}

.contactForm input:focus,
.contactForm textarea:focus {
  background-color: #f0f0f0; /* Light focus effect */
}

.submitButton {
  padding: 15px;
  background-color: #4caf50;
  border: none;
  color: white;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #43a047;
}

.socialLinks {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.icon {
  color: #333; /* Dark icons */
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
  transform: scale(1.2);
  color: #4caf50; /* Green hover effect */
}
