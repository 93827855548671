.skillsSection {
  padding: 50px 0;
  text-align: center;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.skillCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.
}